import React, { useEffect } from 'react';
import { pageTitle } from '../../helper';
import Hero from '../Hero';
import Hero10 from '../Hero/Hero10';
import parse from 'html-react-parser';
import Button from '../Button';
import Spacing from '../Spacing';
import MovingText from '../MovingText/MovingText2';

const bgImageUrl = "/images/hero_bg.jpeg";

const dataa = [
  { title: "PORTUGAL" },
  { title: "ESPANHA" },
  { title: "ITÁLIA" },
  { title: "BRASIL" },
  { title: "CANADA" },
];

export default function Home() {
  pageTitle('Home');

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <div className="cs-hero cs-style7 cs-fixed_bg-nut" id="home" style={{ backgroundImage: `url(${bgImageUrl})` }}>
        <div className="container">
          <h1 className="cs-hero_title">FLY SMARTER</h1>
        </div>
        <div className="container">
          <div className="cs-hero_text_in">
            <div className="cs-hero_subtitle">info@newuavtech.com</div>
            <div className="cs-hero_btn">
            <Button btnLink="https://agency.newuavtech.com/" btnText="Agency Department" />
          </div>
          </div>
        </div>
        <div className="cs-hero_img_1">
          <img src="images/hero_img_2.png" alt="Hero Img" />
        </div>
        <div className="cs-hero_img_2">
          <img src="images/hero_img_3.png" alt="Hero Img" />
        </div>
        <div className="cs-hero_img_3">
          <img src="images/hero_img_4.png" alt="Hero Img" />
        </div>
      </div>
    </>
  );
}
