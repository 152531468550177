import React from 'react';
import Div from '../Div';
import Cta from '../Cta';
import Spacing from '../Spacing';
import MovingText from '../MovingText/MovingText2';
import './footer.scss';

const dataa = [
  { title: "PORTUGAL" },
  { title: "ESPANHA" },
  { title: "ITÁLIA" },
  { title: "BRASIL" },
  { title: "CANADA" },
];

export default function Footer({ copyrightText, logoSrc, logoAlt, text }) {
  return (
    <footer className="cs-fooer">

      <Spacing lg="100" md="30" />
      <MovingText data={dataa} />
      <Spacing lg="100" md="10" />

      <Div className="container">
        <Div className="cs-bottom_footer">
          <Div className="cs-bottom_footer_left">
            <Div className="cs-copyright">Copyright © 2024 NEWUAV TECH LDA</Div>

          </Div>
          <Div className="cs-bottom_footer_right">
            <img src='/images/prr-a.png' className='prr-logos' alt='prr'></img>
          </Div>
        </Div>
      </Div>
    </footer>
  );
}
